import * as React from 'react';

import { RouteComponentProps } from '@reach/router';
import Button from '@rotaryintl/harris-button';
import RelatedLinks from '@rotaryintl/harris-related-links';
import { Link as GatsbyLink } from 'gatsby';

import { OneColumn } from '@components/Layouts/OneColumn';
import Loading from '@components/Loading';
import AccessDenied from '@components/Pages/AccessDenied';

import { ClubInfo } from './ClubInfo';
import {
  CountryId,
  noClubInfoCountries,
  noDonateWidgetCountries,
  RIBI_COUNTRY_LIST,
} from './constants';
import DonateWidget from './DonateWidget';
import { AlandIslands } from './PaymentInstructionsByCountry/AlandIslands';
import { India } from './PaymentInstructionsByCountry/India';
import { Korea } from './PaymentInstructionsByCountry/Korea';
import { Kosovo } from './PaymentInstructionsByCountry/Kosovo';
import { Philippines } from './PaymentInstructionsByCountry/Philippines';
import { Ribi } from './PaymentInstructionsByCountry/Ribi';
import './styles.css';

import { getClubFinancePath, getClubIdFromPath } from '@use-cases/clubs';

import { useFetchClubInvoiceData } from '@repositories/clubs/hooks';

import { getClubTypeName } from '@utils/getClubTypeName';
import { localizedNavigate } from '@utils/localized-navigate';

import { Helmet } from '@external/react-helmet-async';
import { useTranslation } from '@external/react-i18next';

import { DIS } from '@typings/dis';
import { Enum, From, mapEnum } from '@typings/map-enum';
import { LanguageCode } from '@typings/official-directory';

interface BackButtonProps {
  t: (key: string, defaultValue?: string) => string;
  onBackClick: () => void;
}

const BackButton: React.FC<BackButtonProps> = ({ t, onBackClick }) => (
  <Button
    clickHandler={onBackClick}
    icon="fas-angle-left"
    size="md"
    variant="text"
    className="mt-8"
  >
    {t('back-link-text', 'Back')}
  </Button>
);

interface Props extends RouteComponentProps {
  clubId: string;
}

const ClubInvoice: React.FC<Props> = ({ clubId }) => {
  const { t, i18n } = useTranslation();
  const { data: clubData, loading: clubDataLoading } = useFetchClubInvoiceData(
    clubId
  );
  const data = clubData?.club;

  if (clubDataLoading || !data) {
    return <Loading />;
  }

  const { clubType, clubName, riClubId } = data;
  const formattedType = mapEnum(
    From.DIS,
    Enum.ClubType,
    clubType as DIS.ClubType
  );
  const formattedClubType = getClubTypeName(t, formattedType);
  const hasClubFinanceAccess = data.financeOperationAccess?.hasAccess;
  const clubCountry = data.physicalLocation?.countryId || '';
  const isRibiCountry =
    RIBI_COUNTRY_LIST.includes(clubCountry) &&
    clubType === DIS.ClubTypeEnum.RotaryClub;
  const showDonateWidget = !(
    isRibiCountry || noDonateWidgetCountries.includes(clubCountry)
  );
  const showClubInfo = !(
    isRibiCountry || noClubInfoCountries.includes(clubCountry)
  );

  const storedActiveTabData = localStorage.getItem('activeTabValue');
  const activeTabData = storedActiveTabData
    ? JSON.parse(storedActiveTabData)
    : [];
  const navigatedFromActiveClubTabs = activeTabData[0]?.value;

  const handleBackNavigation = () => {
    let redirectToClubFinancePath = '/my-clubs';
    if (navigatedFromActiveClubTabs) {
      const homeClubId = getClubIdFromPath(navigatedFromActiveClubTabs);
      redirectToClubFinancePath = getClubFinancePath(homeClubId);
    }
    localizedNavigate(redirectToClubFinancePath);
  };

  const langCode = i18n.language.toLowerCase() as LanguageCode;

  const links = [
    {
      children: `${t(
        'club-invoice.related-link.current-invoice',
        'Current Invoice'
      )}`,
      to: `/${langCode}/club-invoice-report/${clubId}?docType=I&isCurrentInvoice=true`,
      withIcon: true,
      LinkComponent: GatsbyLink,
    },
    {
      children: `${t(
        'club-invoice.related-link.current-invoice-details',
        'Current Invoice Details'
      )}`,
      to: `/${langCode}/club-invoice-report/${clubId}?docType=D&isCurrentInvoice=true`,
      withIcon: true,
      LinkComponent: GatsbyLink,
    },
    {
      children: `${t(
        'club-invoice.related-link.previous-invoice',
        'Previous Invoice'
      )}`,
      to: `/${langCode}/club-invoice-report/${clubId}?docType=I&isCurrentInvoice=false`,
      withIcon: true,
      LinkComponent: GatsbyLink,
    },
    {
      children: `${t(
        'club-invoice.related-link.previous-invoice-details',
        'Previous Invoice Details'
      )}`,
      to: `/${langCode}/club-invoice-report/${clubId}?docType=D&isCurrentInvoice=false`,
      withIcon: true,
      LinkComponent: GatsbyLink,
    },
  ];

  const getPaymentInstructionsByCountry = (country: string) => {
    switch (country) {
      case CountryId.RIBI:
        return <Ribi />;
      case CountryId.IND:
        return <India />;
      case CountryId.KOR:
        return <Korea />;
      case CountryId.PHL:
        return <Philippines />;
      case CountryId.XKX:
        return <Kosovo />;
      case CountryId.ALA:
        return <AlandIslands />;
      default:
        return null;
    }
  };

  if (!hasClubFinanceAccess) {
    return (
      <OneColumn className="mb-20">
        <BackButton t={t} onBackClick={handleBackNavigation} />
        <AccessDenied />
      </OneColumn>
    );
  }

  return (
    <OneColumn>
      <Helmet
        titleTemplate={`${t(
          'metadata.title.club-invoice',
          'Club Invoice'
        )}: %s | ${t('metadata.title.default', 'My Rotary')}`}
      />
      <BackButton
        t={t}
        onBackClick={() => localizedNavigate(getClubFinancePath(clubId))}
      />
      <h1 className="font-semibold text-xl-38 leading-lg-45 mobile-m:text-xl-48 mobile-m:leading-xl-57 tablet:text-xl-58 tablet:p-0 m-0 my-6 tablet:mb-8 tablet:leading-xl-70">
        {t('club-invoice.title', 'Club Invoice')}
      </h1>
      <div className="flex flex-col tablet:flex-row tablet:justify-between gap-8 tablet:gap-28 wrap mb-12 tablet:mb-20">
        <div className="w-full tablet:w-2/3">
          {showClubInfo && (
            <ClubInfo
              formattedClubType={formattedClubType}
              clubName={clubName}
              clubType={clubType}
            />
          )}

          {(isRibiCountry || noDonateWidgetCountries.includes(clubCountry)) &&
            getPaymentInstructionsByCountry(
              isRibiCountry ? CountryId.RIBI : clubCountry
            )}
        </div>

        {showClubInfo && (
          <div className="w-full tablet:w-1/3">
            <RelatedLinks
              title={t(
                'club-invoice.related-link.title',
                'Club Invoice and Details'
              )}
              description={t(
                'club-invoice.related-link.description',
                'View current and previous club invoices and details.'
              )}
              links={links}
            />
          </div>
        )}
      </div>

      {showDonateWidget && (
        <DonateWidget
          riClubId={riClubId}
          clubType={clubType.replaceAll('_', ' ')}
        />
      )}
    </OneColumn>
  );
};

export default ClubInvoice;
