import React from 'react';

import Alert from '@rotaryintl/harris-alert';

import { useTranslation } from '@external/react-i18next';

export const Ribi = () => {
  const { t } = useTranslation();
  return (
    <Alert variant="info" isDismissible={false}>
      {t(
        'club-invoice.ribi-countries.note',
        'Please go to <a href="https://dms.rotarygbi.org/login" target="_blank"><b>https://dms.rotarygbi.org/login</b></a> to access your club’s financials.'
      )}
    </Alert>
  );
};
